//
// Dakara Project
//
// Sublisting style file
//


// sublisting sizes
$sublisting-entry-height: $subrow-height;
$sublisting-entry-control-font-size: $subrow-icon-font-size;


// `sublisting` class:
//
// This module is a submodule for `listing` and aim to style inner lists of a
// list entry.
.sublisting {
    display: table-cell;
    list-style-type: none;
    padding: 0;
    width: 100%;

    @include make-smartphone {
        display: block;
    }

    .sublisting-entry {
        $height: $sublisting-entry-height;

        align-items: center;
        background: darken($neutral-mid, 2%);
        display: flex;
        min-height: $height;

        &:nth-child(even) {
            background: darken($neutral-mid, 1%);

            .listing-entry:nth-child(even) {
                background: darken($neutral-mid-light, 2%);
            }
        }

        .listing-entry:nth-child(even) {
            background: darken($neutral-mid-light, 3%);
        }

        .controls {
            @include make-controls($height, $sublisting-entry-control-font-size);

            align-self: flex-start;
        }
    }
}
