//
// Dakara Project
//
// Tags management page style file
//

#song-tag-list {
    .song-tag-list-listing {
        .name {
            text-align: left;
        }

        .listing-header .color {
            @include make-gap(padding, right);

            text-align: right;
        }

        .color {
            .notified {
                z-index: 900;

                &.color-form-notified {
                    z-index: 800;
                }
            }

            .field {
                flex: 1;
            }
        }

        .set {
            padding: 0;
        }

        .color,
        .enableness {
            .form {
                height: $listing-entry-height;
                justify-content: center;
            }
        }

        .enableness {
            font-size: 1.5em;
            text-align: center;
        }

        .controls-col {
            width: initial;
        }

        .color-form-notified {
            display: flex;

            .form {
                flex: 1;

                .hue .input {
                    flex: 1;
                }
            }
        }

        .listing-entry {
            $background: hsl(0, $custom-color-saturation, $custom-color-luminosity);

            .display-color {
                background: $background;

                &:focus {
                    background: lighten($background, 5%);
                }
            }

            &:hover .display-color {
                background: lighten($background, 3.5%);
            }

            .display-color-container {
                display: flex;
                height: 100%;
                justify-content: flex-end;

                .display-color {
                    height: 100%;
                    width: $listing-entry-height;
                }
            }
        }
    }
}
