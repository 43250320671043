//
// Dakara Project
//
// Users management page style file
//


@import "edit";


#users-list {
    .users-list {
        .username {
            text-align: left;
        }

        .listing-entry {
            .username {
                overflow: hidden;
                overflow-wrap: break-word;

                @include make-tablet {
                    max-width: calc(
                        100vw /* the width of the window */
                        - 2 * #{$listing-entry-height} /* the control-col with 2 controls */
                        - 1.5 * #{$listing-entry-height} /* the superuser col */
                        - 3 * 1.5 * #{$listing-entry-height} /* 3 permission cols */
                        - 0.3 * #{$listing-entry-height} /* arbitrary coefficient */
                    );
                }

                @include make-smartphone {
                    max-width: calc(
                        100vw /* the width of the window */
                        - 2 * #{$listing-entry-height} /* the control-col with 2 controls */
                        - #{$listing-entry-height} /* the superuser col */
                        - 3 * #{$listing-entry-height} /* 3 permission cols */
                    );
                }
            }

            .permission {
                text-align: center;

                @include make-smartphone {
                    .permission-text {
                        text-transform: uppercase;

                        .hideable {
                            display: none;
                        }
                    }
                }
            }

            .superuser,
            .validated {
                font-size: 1.5em;
                text-align: center;
            }
        }
    }

    .create-user {
        @include make-gap(padding, vertical);
    }
}
