//
// Dakara project
//
// Library style file
//


@import "song";
@import "work_and_artist";
@import "artist";
@import "work";


// `library` id:
//
// This component stylises the library, which contains a library chooser, a
// searchbox, a container of library entries and a navigator.
#library {

    // Query box for searching items depending on the page
    // That is, songs for Songs library, artists for Artists library and works
    // (anime, game...) for Works library
    .library-searchbox {
        @include make-gap(padding, vertical);

        background: $brand-primary-darker;

        .set {
            .field {
                flex: 1;

                .fake {
                    display: flex;
                    padding-right: 0;

                    .faked {
                        flex: 1;
                    }

                    .controls {
                        @include make-controls($form-field-height, 1.25em);
                    }
                }
            }
        }

        .controls {
            > .control .icon {
                font-size: 1.4em;
                line-height: 1.4em;
            }
        }

        .help {
            @include make-gap(padding, top);

            color: $brand-primary;

            &.help-enter {
                max-height: 0;
                overflow: hidden;

                &.help-enter-active {
                    max-height: (3 * $listing-entry-height);
                    transition: max-height 300ms ease-out;
                }
            }

            &.help-exit {
                max-height: (3 * $listing-entry-height);
                overflow: hidden;

                &.help-exit-active {
                    max-height: 0;
                    transition: max-height 150ms ease-out;
                }
            }

            .example {
                color: $brand-primary-lighter;
            }
        }
    }

    // Here goes the styles for the library pages
    // These pages are:
    //     Songs library,
    //     Artists library,
    //     Works library (and all the different works, like Anime, Game...)
    // The current class gathers all the common elements needed by the different pages
    // .library-list {
    // }
}
