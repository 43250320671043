//
// Dakara Project
//
// Users management user edit page style file
//

#users-edit {
    .form {
        @include make-gap(padding, vertical);
    }
}
