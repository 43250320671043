//
// Dakara Project
//
// Minimal playlist entry widget file
//

.playlist-entry-minimal {
    .user-widget {
        margin-top: -0.25em;
    }
}
