//
// Dakara Project
//
// HTML body style file
//


body {
    background: $text-dark;
    color: $text-light;
    margin: 0;
    padding: 0;
}
