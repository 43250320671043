//
// Dakara Project
//
// Song playing or queuing info
//


// `play-queue-info` class:
//
// Data relative to the status of the song in the playlist.
.play-queue-info {
    background: $brand-info;
    box-sizing: border-box;
    color: $text-dark;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0.5em;
    text-align: center;
    width: 2 * $listing-entry-height;

    .owner {
        @include text-truncate();

        font-size: 0.85em;
        margin-bottom: 0.25em;
    }

    .played {
        .icon {
            padding-right: 0.5em;
        }
    }

    .queueing {
        .icon {
            padding-left: 0.5em;
        }
    }

    .playing,
    .queueing,
    .played {
        font-size: 0.75em;
    }

    .instrumental {
        font-size: 0.75em;
    }

    // appearance transition of the playlist info class
    &.play-queue-info-enter {
        max-width: 0;
        overflow: hidden;

        &.play-queue-info-enter-active {
            max-width: (2 * $listing-entry-height);
            transition: max-width 300ms ease-out;
        }
    }

    // disappearance transition of the playlist info class
    &.play-queue-info-exit {
        max-width: (2 * $listing-entry-height);
        overflow: hidden;

        &.play-queue-info-exit-active {
            max-width: 0;
            transition: max-width 150ms ease-out;
        }
    }
}

// alteration of colors when used in a listing
.listing-entry {
    &:nth-child(2n) {
        .play-queue-info {
            background: $brand-info-light;
        }
    }

    .hoverizable:hover,
    &.hoverizable:hover {
        .play-queue-info {
            background: $brand-info-lighter;
        }
    }
}
