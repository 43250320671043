//
// Dakara Project
//
// Rules aiming for all or generic tags style file
//


// make links
a {
    color: $brand-primary-light;
    text-decoration: underline;
}

// highlight tag
mark {
    background: $brand-primary;
    color: $text-dark;
}

// selection highlighting
::selection {
    background: $brand-primary;
    color: $text-dark;
}

// make headers thin and marginless
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 100;
    margin: 0;
}

h2 {
    font-size: 2em;
}

h3 {
    font-size: 1.75em;
}

// make long text clearly readible
@mixin make-long-text() {
    font-size: 1.2em;
}

p {
    @include make-long-text();
}

ul {
    list-style-type: square;
}

// remove border radius from button in some browsers
button {
    border-radius: 0;
}
