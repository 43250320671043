//
// Dakara Project
//
// Header style file
//


// header sizes
$header-height: $tab-bar-height;

#header {
    background: darken($neutral-mid, 3%);
    display: flex;
    height: $header-height;
    justify-content: space-between;
    position: relative;
    z-index: 1200;

    h1 {
        @include make-gap(padding, horizontal);

        font-size: xx-large;
        line-height: $tab-bar-height * 0.9;

        @include make-smartphone {
            font-size: x-large;
        }

        a {
            color: inherit;
            text-decoration: none;
        }
    }
}
