//
// Dakara Project
//
// Artist widget style file
//

// `artist-widget` class:
//
// This module is used to style an artist.
.artist-widget {
    &.truncatable {
        @include text-truncate();
    }

    .icon {
        margin-right: 0.5em;
    }
}
