//
// Dakara Project
//
// User page style file
//


#user {
    .header {
        align-items: center;
        display: flex;
        flex-wrap: wrap;

        .user-name-block {
            @include make-gap(padding, horizontal);

            overflow: hidden;
            overflow-wrap: break-word;

            h1 {
                font-size: 3em;
            }

            h2 {
                font-size: 1.5em;
            }
        }

        .permissions {
            flex: 1 0 auto;
            text-align: right;
        }

    }

    .change-email-disabled {
        @include make-gap(margin, top);
    }
}
