//
// Dakara Project
//
// Tokens settings style file
//

// Sizes
$tokens-control-size: $row-height;
$tokens-control-font-size: 1.25em;

#tokens {
    .token-box {
        h3 {
            @include make-gap(padding, horizontal);
        }

        .create,
        .created {
            @include make-gap(margin, top, 0.5);
        }

        .copy-help,
        .revoke {
            @include make-gap(margin, top, 0.5);
        }

        .revoke {
            &.controls {
                @include make-controls($tokens-control-size, $tokens-control-font-size);

                display: flex;
                justify-content: flex-end;

                > .control {
                    @include make-gap(padding, horizontal);

                    width: unset;
                }

                .notification .controls {
                    @include make-controls($tokens-control-size, $tokens-control-font-size);
                }
            }
        }

        & + .token-box {
            // create margin for following token boxes
            @include make-gap(margin, top);
        }

        .created-enter {
            // approximate min and max height
            height: $ribbon-control-height + 1 * $gap-vertical;
            overflow-y: hidden;

            &.created-enter-active {
                height: 2 * $ribbon-control-height + $tokens-control-size + 1 * $gap-vertical;
                transition: height 300ms ease-out;
            }
        }

        .created-exit {
            // approximate min and max height
            height: 2 * $ribbon-control-height + $tokens-control-size + 1 * $gap-vertical;
            overflow-y: hidden;

            &.created-exit-active {
                height: $ribbon-control-height + 1 * $gap-vertical;
                transition: height 150ms ease-out;
            }
        }
    }
}
