//
// Dakara Project
//
// Karaoke style file
//


@import "playlist_info_bar";
@import "player";
@import "kara_status_notification";


#karaoke {
    position: sticky;
    top: -$playlist-info-bar-height;
    z-index: 1000;

    @include make-smartphone {
        top: -$playlist-info-bar-height-smartphone;
    }

    // if the player has controls activated, the player item will hide the
    // playlist-info-bar item on scroll
    &.player-with-controls {
        top: -$playlist-info-bar-height - $player-controls-height - $gap-vertical;

        @include make-smartphone {
            top: -$playlist-info-bar-height-smartphone - $player-controls-height-smartphone - $gap-vertical-smartphone;
        }
    }
}
