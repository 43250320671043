//
// Dakara Project
//
// Playlist info bar style file
//


$playlist-info-bar-height: 4rem;
$playlist-info-bar-height-smartphone: 7rem;


// `playlist-info-bar` id:
//
// The playlist info bar gives some pieces of information on the status of the
// playlist, tipycally the amount of songs in queue and when the
// playlist/karaoke will end.
//
// Info don't have specific classes.
#playlist-info-bar {
    @include make-gap(padding, horizontal);

    align-items: center;
    background: $brand-primary;
    color: $text-dark;
    cursor: pointer;
    display: flex;
    height: $playlist-info-bar-height;
    justify-content: space-between;
    overflow: hidden;
    text-decoration: none;

    @include make-smartphone {
        font-size: 0.85em;
        height: $playlist-info-bar-height-smartphone;
    }

    &:hover,
    &:focus, {
        background: $brand-primary-lighter;
    }

    .item {
        align-items: center;
        display: flex;
        overflow: hidden;
        white-space: nowrap;

        @include make-smartphone {
            align-items: flex-start;
            flex-direction: column;
        }

        &:not(:last-child) {
            @include make-gap(padding, right);
        }

        &:last-child {
            @include make-smartphone {
                align-items: flex-end;
            }
        }

        .emphasis {
            font-size: 2.5em;
            font-weight: 200;

            @include make-smartphone {
                font-size: 2em;
            }

            // if the text comes after the emphasis
            + .text {
                margin-left: 0.5em;
                margin-right: 0;
                text-align: left;

                @include make-smartphone {
                    margin-left: 0;
                }
            }
        }

        .text {
            font-size: 1em;
            margin-right: 0.5em;
            overflow: hidden;
            text-align: right;

            @include make-smartphone {
                margin-right: 0;
            }

            .line {
                @include text-truncate();

                &.detail {
                    // using opacity creates weird effects with the sticky player
                    color: change-color($text-dark, $alpha: 0.7);
                }

                + .line {
                    margin-top: -0.25em;
                }
            }
        }

        // specific rules
        &.next-entry {
            .title,
            .user-widget {
                @include text-truncate();
            }
        }

        &.amount {
            // do not show next entry on smartphone
            @include make-smartphone {
                display: none;
            }
        }
    }
}
