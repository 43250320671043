//
// Dakara Project
//
// Error page style file
//

#error-page {
    .url {
        @include make-gap(padding, horizontal);

        background: $brand-danger;
        color: $brand-danger-darker;
        display: inline-block;
        font-size: 1.1em;
        line-height: 1.5em;
        margin-bottom: 1em;
        margin-left: 0;
        margin-right: 0;

        &::selection {
            background: $brand-danger-darker;
            color: $brand-danger;
        }
    }
}
