//
// Dakara Project
//
// Main style file
//


@import "~normalize.css/normalize.css";
@import "thirdparty/preboot";


/*!
 * Dakara style rules
 */


@import "base/base";
@import "components/components";
