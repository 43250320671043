//
// Dakara Project
//
// User widget style file
//


.user-widget {
    white-space: nowrap;

    &:not(.no-resize) {
        @include make-smartphone {
            font-size: 0.85em;
        }
    }

    .icon {
        margin-right: 0.5em;
    }
}
