//
// Dakara Project
//
// Tags style file
//


// `tag` class:
//
// The `tag` class is a basic style for tags in musics. It can be extended with 8
// different colorized subclasses.
//
// Its size is determined in the `listing-entry-song` class, whether the song is
// displayed in compact or in expanded view.
.tag {
    $color: hsl(0, $custom-color-saturation, $custom-color-luminosity);

    @include make-font();

    background-color: $color;
    border-style: none;
    color: change-color($text-dark, $alpha: 0.5);
    display: inline-block;
    font-size: medium;
    font-weight: 700;
    outline: none;
    padding: 0 $gap-horizontal;

    &.clickable {
        cursor: pointer;

        .icon {
            font-size: 1.2em;
            margin-right: 0.5em;
        }

        &:hover,
        &:focus {
            background-color: lighten($color, 5%);
        }
    }

    &.disabled {
        background-color: desaturate($color, 33%);
        opacity: 0.5;
    }

    &::selection {
        // the real color of the background will change due to the
        // `hue-rotate` filter
        background: red;
    }
}
