//
// Dakara Project
//
// Notification for player status style file
//


// kara status notification sizes
$kara-status-notification-height: $form-field-height;
$kara-status-notification-font-size: $form-control-font-size;


// `kara-status-notification` class: message displayed to the playlist
// manager when the kara is stopped.
#kara-status-notification {
    .content {
        padding: 0;
    }

    .ribbon {
        @include make-gap(padding, vertical);

        align-items: center;
        display: flex;

        .control {
            @include make-gap(padding, horizontal);

            font-size: $kara-status-notification-font-size;
            line-height: $kara-status-notification-height;
        }
    }
}
