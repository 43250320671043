//
// Dakara Project
//
// Main div style file
//


#main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    > .column {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        @include make-desktop {
            @include center-block();

            width: $page-width-max;
        }

        > .content {
            flex-grow: 1;
        }
    }
}
