//
// Dakara project
//
// Colors style file
//


// stylelint-disable

// brand colors
$brand-primary: hsl(289, 80%, 35%);
$brand-success: hsl(159, 50%, 55%);
$brand-warning: hsl( 43, 80%, 50%);
$brand-danger:  hsl( 12, 69%, 47%);
$brand-info:    hsl(224, 57%, 50%);

// darkish brand colors
$brand-primary-darkish: darken($brand-primary, 7%);
$brand-success-darkish: darken($brand-success, 5%);
$brand-warning-darkish: darken($brand-warning, 20%);
$brand-danger-darkish:  darken($brand-danger,  11%);
$brand-info-darkish:    darken($brand-info,    9%);

// darkened brand colors
$brand-primary-darkened: darken($brand-primary, 9.5%);
$brand-success-darkened: darken($brand-success, 7.5%);
$brand-warning-darkened: darken($brand-warning, 10%);
$brand-danger-darkened:  darken($brand-danger,  15%);
$brand-info-darkened:    darken($brand-info,    15%);

// dark brand colors
$brand-primary-dark:     darken($brand-primary, 12%);
$brand-success-dark:     darken($brand-success, 10%);
$brand-warning-dark:     darken($brand-warning, 15%);
$brand-danger-dark:      darken($brand-danger,  20%);
$brand-info-dark:        darken($brand-info,    20%);

// darker brand colors
$brand-primary-darker:   darken($brand-primary, 20%);
$brand-success-darker:   darken($brand-success, 20%);
$brand-warning-darker:   darken($brand-warning, 20%);
$brand-danger-darker:    darken($brand-danger,  26%);
$brand-info-darker:      darken($brand-info,    20%);

// light brand colors
$brand-primary-light:    lighten($brand-primary, 2.5%);
$brand-success-light:    lighten($brand-success, 5.5%);
$brand-warning-light:    lighten($brand-warning, 4.5%);
$brand-danger-light:     lighten($brand-danger,  4.5%);
$brand-info-light:       lighten($brand-info,    3.5%);

// lighter brand colors
$brand-primary-lighter:  lighten($brand-primary,  7%);
$brand-success-lighter:  lighten($brand-success,  7%);
$brand-warning-lighter:  lighten($brand-warning, 10%);
$brand-danger-lighter:   lighten($brand-danger,   7%);
$brand-info-lighter:     lighten($brand-info,     7%);

// neutral colors
$neutral-clear: $black-60;
$neutral-soft:  $black-70;
$neutral-mid:   $black-80;

// light neutral corors
$neutral-clear-light:    lighten($neutral-clear, 2.5%);
$neutral-soft-light:     lighten($neutral-soft,  2.5%);
$neutral-mid-light:      lighten($neutral-mid,   2.5%);

// lighter neutral corors
$neutral-clear-lighter:  lighten($neutral-clear, 7%);
$neutral-soft-lighter:   lighten($neutral-soft,  7%);
$neutral-mid-lighter:    lighten($neutral-mid,   7%);

// text colors
$text-light: $black-60;
$text-dark:  $black-90;

// custom colors saturation and luminosity
$custom-color-saturation: 55%;
$custom-color-luminosity: 55%;

// stylelint-enable

// create colors
@mixin make-color($background, $color, $background-selection) {
    background: $background;
    color: $color;

    ::selection {
        background: $background-selection;
    }
}

.primary {
    @include make-color($brand-primary-darker, $brand-primary-light, $brand-primary);
}

.success {
    @include make-color($brand-success-darker, $brand-success-light, $brand-success);
}

.warning {
    @include make-color($brand-warning-darker, $brand-warning-light, $brand-warning);
}

.danger {
    @include make-color($brand-danger-darker, $brand-danger-light, $brand-danger);
}

.info {
    @include make-color($brand-info-darker, $brand-info-light, $brand-info);
}
