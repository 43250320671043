//
// Dakara Project
//
// Footer style file
//


#footer {
    @include make-gap(padding, vertical);

    background: darken($neutral-mid, 3%);
    margin: 0;
    text-align: right;

    h2 {
        @include make-gap(padding, horizontal);

        font-size: x-large;

        .version {
            font-size: 0.6em;
            font-weight: 300;
        }
    }

    .contact {
        @include make-gap(padding, top);

        font-size: small;

        p {
            margin-bottom: 0;
            margin-top: 0;
        }
    }
}
